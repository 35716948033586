.contact {
  .section-inner {
    padding-top: $contact--padding-t__mobile;
    padding-bottom: $contact--padding-b__mobile;
  }
}

.contact-slogan {
  margin-bottom: $contact-slogan--padding-v__mobile;
}

@include media(">medium") {
  .contact {
    .section-inner {
      padding-top: $contact--padding-t__desktop;
      padding-bottom: $contact--padding-b__desktop;
    }
  }

  .contact-split {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .contact-slogan {
    margin-bottom: $contact-slogan--padding-v__desktop;

    .contact-split & {
      margin-bottom: 0;
      margin-right: $contact-slogan--padding-h__desktop;
    }
  }
}
