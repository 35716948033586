.welcome {
  .section-inner {
    padding-top: $welcome--padding-t__mobile;
    padding-bottom: $welcome--padding-b__mobile;
  }
}

.welcome-inner {
  // Affects only welcome full
  > .welcome-content + .welcome-figure,
  > .welcome-figure + .welcome-content {
    margin-top: $welcome--inner-padding-v_mobile;
  }

  > .welcome-figure {
    > a {
      display: inline-flex;
      // prevents stretching
      align-items: center;
      vertical-align: top;
    }
  }
}

@include media("<=medium") {
  .welcome {
    .split-wrap {
      .split-item {
        .split-item-content {
          margin-bottom: $welcome--inner-padding-v_mobile;
        }
      }

      &.invert-mobile {
        .split-item {
          .split-item-image {
            margin-bottom: $welcome--inner-padding-v_mobile;
          }
        }
      }
    }
  }
}

@include media(">medium") {
  .welcome {
    .section-inner {
      padding-top: $welcome--padding-t__desktop;
      padding-bottom: $welcome--padding-b__desktop;
    }
  }

  .welcome-inner {
    // Affects only welcome full
    > .welcome-content + .welcome-figure,
    > .welcome-figure + .welcome-content {
      margin-top: $welcome--inner-padding-v_desktop;
    }
  }
}
